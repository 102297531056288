import React, { useContext } from "react"
import classes from "./index.module.scss"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Input } from "reactstrap"

import { DocumentIdContext } from "pages/add-invoice/context/documentIdContext"
import { TabelContext } from "pages/add-invoice/context/tabelContext"

const convertDate = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const covertedDate = new Date()

  const date =
    ("0" + covertedDate.getDate()).slice(-2) +
    " " +
    months[covertedDate.getMonth()] +
    ", " +
    covertedDate.getFullYear()

  const time =
    ("0" + covertedDate.getHours()).slice(-2) +
    ":" +
    ("0" + covertedDate.getMinutes()).slice(-2) +
    ":" +
    ("0" + covertedDate.getSeconds()).slice(-2)

  return { date, time }
}

const randomMisRef = Math.floor(Math.random() * 90000000) + 10000000

const HeaderInputs = () => {
  const documentIdCtx = useContext(DocumentIdContext)
  const { paperId } = documentIdCtx

  const TabelCtx = useContext(TabelContext)
  const { updateHeaderDataHandler } = TabelCtx

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      misRet: randomMisRef,
      salesType: "",
      preparedBy: "",
      date: "",
      time: "",
      customerName: "",
      customerNo: "",
      mobile: "",
      email: "",
      purchaseType: "",
      marketPlacePurchased: "",
      // Get from user who logged in
      systemUser: "",
    },
    validationSchema: Yup.object({
      misRet: Yup.string().required("Please Enter MIS Ret"),
      salesType: Yup.string().required("Please Enter Sales Type"),
      preparedBy: Yup.string().required("Please Enter Prepared By"),
      date: Yup.string().required("Please Enter Date"),
      time: Yup.string().required("Please Enter Time"),
      customerName: Yup.string().required("Please Enter Customer Name"),
      customerNo: Yup.string().required("Please Enter Customer No"),
      mobile: Yup.string().required("Please Enter Mobile"),
      email: Yup.string()
        .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, "Please Enter Valid Email")
        .required("Please Enter Your Email"),
      purchaseType: Yup.string()
        .required("Please Enter Purchase Type")
        .test("valid-purchaseType", "Invalid Purchase Type", value =>
          [
            "onsite-purchase",
            "other-purchase",
            "self-pickup",
            "delivery",
            "market-place-purchased",
          ].includes(value)
        ),
      marketPlacePurchased: Yup.string().when("purchaseType", {
        is: "market-place-purchased",
        then: Yup.string()
          .oneOf(["talabat", "snoonu", "avey", "refeeq"], "Invalid Marketplace")
          .required("Please Enter Marketplace"),
      }),
    }),
    onSubmit: values => {
      console.log(values)
      //
    },
  })

  // define date and time in first render
  React.useEffect(() => {
    const { date, time } = convertDate()
    validation.setFieldValue("date", date)
    validation.setFieldValue("time", time)
  }, [])

  React.useEffect(() => {
    if (validation.values.purchaseType !== "market-place-purchased") {
      validation.setFieldValue("marketPlacePurchased", "")
    }
  }, [validation.values.purchaseType])

  // update context header data
  React.useEffect(() => {
    updateHeaderDataHandler(validation.values)
  }, [validation.values])

  const handleCheckboxChange = event => {
    const { name, checked } = event.target
    const salesType = checked ? name : ""
    validation.setFieldValue("salesType", salesType)
  }

  const handlePurchaseTypeCheckboxChange = event => {
    const { name, checked } = event.target
    const purchaseType = checked ? name : ""
    validation.setFieldValue("purchaseType", purchaseType)
  }

  const handlePurchaseMarketPlacePurchasedCheckboxChange = event => {
    const { name, checked } = event.target
    const marketPlacePurchased = checked ? name : ""
    validation.setFieldValue("marketPlacePurchased", marketPlacePurchased)
  }

  return (
    <div className={classes.invoice_main_page_header_inputs}>
      <div
        className="bg-white text-danger text-center"
        style={{
          width: "125px",
          marginLeft: "1048px",
          marginTop: "28px",
          fontWeight: "bold",
          fontSize: "14px",
        }}
      >
        {paperId ? paperId : "."}
      </div>
      <div style={{ marginTop: "12px" }}></div>
      <Input
        id="misRet"
        name="misRet"
        type="text"
        className={classes.transparent_input}
        style={{ width: "250px", marginLeft: "940px" }}
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.misRet || ""}
        readOnly
      />
      <div className={classes.invoice_checkbox_container}>
        <Input
          type="checkbox"
          className="form-check-Input"
          style={{ width: "28px", height: "28px" }}
          id="cash"
          name="cash"
          checked={validation.values.salesType === "cash"}
          onChange={handleCheckboxChange}
        />
        <Input
          type="checkbox"
          className="form-check-Input"
          style={{ width: "28px", height: "28px" }}
          id="credit"
          name="credit"
          checked={validation.values.salesType === "credit"}
          onChange={handleCheckboxChange}
        />
      </div>
      <div className={classes.preparedBy_date_time_container}>
        <Input
          id="preparedBy"
          name="preparedBy"
          type="text"
          className={classes.transparent_input}
          style={{ width: "430px", marginLeft: "125px" }}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.preparedBy || ""}
          autocomplete="off"
        />
        <Input
          id="date"
          name="date"
          type="text"
          readOnly
          className={classes.transparent_input}
          style={{ width: "175px", marginLeft: "140px" }}
          value={validation.values.date || ""}
        />
        <Input
          id="time"
          name="time"
          type="text"
          readOnly
          className={classes.transparent_input}
          style={{ width: "160px", marginLeft: "90px" }}
          value={validation.values.time || ""}
        />
      </div>
      <div className={classes.customerName_customerNo_container}>
        <Input
          id="customerName"
          name="customerName"
          type="text"
          className={classes.transparent_input}
          style={{ width: "650px", marginLeft: "155px" }}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.customerName || ""}
          autocomplete="off"
        />
        <Input
          id="customerNo"
          name="customerNo"
          type="text"
          className={classes.transparent_input}
          style={{ width: "220px", marginLeft: "115px" }}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.customerNo || ""}
          autocomplete="off"
        />
      </div>
      <div className={classes.mobile_email_container}>
        <Input
          id="mobile"
          name="mobile"
          type="text"
          className={classes.transparent_input}
          style={{ width: "210px", marginLeft: "90px" }}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.mobile || ""}
          autocomplete="off"
        />
        <Input
          id="email"
          name="email"
          type="text"
          className={classes.transparent_input_itemCode}
          style={{ width: "230px", marginLeft: "50px", fontSize: "8px" }}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.email || ""}
          autocomplete="off"
        />
        <div
          style={{ position: "absolute", marginLeft: "570px", top: "-13px" }}
        >
          <Input
            type="checkbox"
            className="form-check-Input"
            style={{ width: "28px", height: "28px", marginLeft: "8px" }}
            id="onsite-purchase"
            name="onsite-purchase"
            checked={validation.values.purchaseType === "onsite-purchase"}
            onChange={handlePurchaseTypeCheckboxChange}
          />
          <Input
            type="checkbox"
            className="form-check-Input"
            style={{ width: "28px", height: "28px", marginLeft: "144px" }}
            id="other-purchase"
            name="other-purchase"
            checked={validation.values.purchaseType === "other-purchase"}
            onChange={handlePurchaseTypeCheckboxChange}
          />
          <Input
            type="checkbox"
            className="form-check-Input"
            style={{ width: "28px", height: "28px", marginLeft: "137px" }}
            id="self-pickup"
            name="self-pickup"
            checked={validation.values.purchaseType === "self-pickup"}
            onChange={handlePurchaseTypeCheckboxChange}
          />
          <Input
            type="checkbox"
            className="form-check-Input"
            style={{ width: "28px", height: "28px", marginLeft: "138px" }}
            id="delivery"
            name="delivery"
            checked={validation.values.purchaseType === "delivery"}
            onChange={handlePurchaseTypeCheckboxChange}
          />
        </div>
      </div>
      <div className={classes.puchased_places_container}>
        <Input
          type="checkbox"
          className="form-check-Input"
          style={{ width: "28px", height: "28px", marginLeft: "27px" }}
          id="market-place-purchased"
          name="market-place-purchased"
          checked={validation.values.purchaseType === "market-place-purchased"}
          onChange={handlePurchaseTypeCheckboxChange}
        />
        <Input
          type="text"
          className={classes.transparent_input}
          readOnly
          style={{ width: "200px", marginLeft: "190px", marginTop: "7px" }}
          value={validation.values.marketPlacePurchased || ""}
        />
        {validation.values.purchaseType === "market-place-purchased" ? (
          <div style={{ marginTop: "7px" }}>
            <Input
              type="checkbox"
              className="form-check-Input"
              style={{
                width: "17.5px",
                height: "17.5px",
                marginLeft: "16.5px",
                borderRadius: "50%",
              }}
              id="talabat"
              name="talabat"
              checked={validation.values.marketPlacePurchased === "talabat"}
              onChange={handlePurchaseMarketPlacePurchasedCheckboxChange}
            />
            <Input
              type="checkbox"
              className="form-check-Input"
              style={{
                width: "17.5px",
                height: "17.5px",
                marginLeft: "188px",
                borderRadius: "50%",
              }}
              id="snoonu"
              name="snoonu"
              checked={validation.values.marketPlacePurchased === "snoonu"}
              onChange={handlePurchaseMarketPlacePurchasedCheckboxChange}
            />
            <Input
              type="checkbox"
              className="form-check-Input"
              style={{
                width: "17.5px",
                height: "17.5px",
                marginLeft: "186px",
                borderRadius: "50%",
              }}
              id="avey"
              name="avey"
              checked={validation.values.marketPlacePurchased === "avey"}
              onChange={handlePurchaseMarketPlacePurchasedCheckboxChange}
            />
            <Input
              type="checkbox"
              className="form-check-Input"
              style={{
                width: "17.5px",
                height: "17.5px",
                marginLeft: "159px",
                borderRadius: "50%",
              }}
              id="refeeq"
              name="refeeq"
              checked={validation.values.marketPlacePurchased === "refeeq"}
              onChange={handlePurchaseMarketPlacePurchasedCheckboxChange}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default HeaderInputs
