import {
  GET_INVOICES,
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,

  GET_INVOICE_DETAIL,
  GET_INVOICE_DETAIL_FAIL,
  GET_INVOICE_DETAIL_SUCCESS,

  GET_INVOICES_STATUS,
  GET_INVOICES_STATUS_SUCCESS,
  GET_INVOICES_STATUS_FAIL,
  POST_INVOICES_STATUS,
  POST_INVOICES_STATUS_SUCCESS,
  POST_INVOICES_STATUS_FAIL,

  GET_TICKET_STATUS,
  GET_TICKET_STATUS_SUCCESS,
  GET_TICKET_STATUS_FAIL,
  POST_TICKET_STATUS,
  POST_TICKET_STATUS_SUCCESS,
  POST_TICKET_STATUS_FAIL,
  GET_INVOICE_PAYMENT_SUMMARY,
  GET_INVOICE_PAYMENT_SUMMARY_SUCCESS,
  GET_INVOICE_PAYMENT_SUMMARY_FAIL
} from "./actionTypes"
// Get all invoices actions
export const getInvoices = () => ({
  type: GET_INVOICES,
})

export const getInvoicesSuccess = invoices => ({
  type: GET_INVOICES_SUCCESS,
  payload: invoices,
})

export const getInvoicesFail = error => ({
  type: GET_INVOICES_FAIL,
  payload: error,
})

// get invoice detail actions
export const getInvoiceDetails = invoiceId => ({
  type: GET_INVOICE_DETAIL,
  invoiceId,
})

export const getInvoiceDetailSuccess = invoices => ({
  type: GET_INVOICE_DETAIL_SUCCESS,
  payload: invoices,
})

export const getInvoiceDetailFail = error => ({
  type: GET_INVOICE_DETAIL_FAIL,
  payload: error,
})

// Invoice Status list
export const getInvoicesStatus = () => ({
  type: GET_INVOICES_STATUS,
})

export const getInvoicesStatusSuccess = invoices => ({
  type: GET_INVOICES_STATUS_SUCCESS,
  payload: invoices,
})

export const getInvoicesStatusFail = error => ({
  type: GET_INVOICES_STATUS_FAIL, 
  payload: error,
})
// edit invoice status actions
export const postInvoicesStatus = data => ({
  type: POST_INVOICES_STATUS,
  payload: data,
})

export const postInvoicesStatusSuccess = res => ({
  type: POST_INVOICES_STATUS_SUCCESS,
  payload: res,
})

export const postInvoicesStatusFail = error => ({
  type: POST_INVOICES_STATUS_FAIL,
  payload: error,
})

// get ticket status
export const getTicketStatus = () => ({
  type: GET_TICKET_STATUS,
})

export const getTicketStatusSuccess = tickets => ({
  type: GET_TICKET_STATUS_SUCCESS,
  payload: tickets,
})

export const getTicketStatusFail = error => ({
  type: GET_TICKET_STATUS_FAIL,
  payload: error,
})

// edit invoice ticket status actions
export const postTicketStatus = data => ({
  type: POST_TICKET_STATUS,
  payload: data,
})

export const postTicketStatusSuccess = res => ({
  type: POST_TICKET_STATUS_SUCCESS,
  payload: res,
})

export const postTicketStatusFail = error => ({
  type: POST_TICKET_STATUS_FAIL,
  payload: error,
})

export const getInvoicePaymentSummary = (id) => ({
  type: GET_INVOICE_PAYMENT_SUMMARY,
  payload: id
})

export const getInvoicePaymentSummarySuccess = (data) => ({
  type: GET_INVOICE_PAYMENT_SUMMARY_SUCCESS,
  payload: data
})

export const getInvoicePaymentSummaryFail = (error) => ({
  type: GET_INVOICE_PAYMENT_SUMMARY_FAIL, 
  payload: error
})