const documentsListDummyData = [
  {
    docId: "x-0001",
    createdAt: "2024-02-26T09:00:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
    usedStatus: true,
    usedBy: "moderator",
    usedDate: "2024-02-26T09:00:00.000Z",
    price: 5200,
    invoiceId: 90272708,
  },
  {
    docId: "x-0002",
    createdAt: "2024-02-25T10:30:00.000Z",
    createdBy: "admin",
    assigned: false,
    assignedTo: null,
    assignedBy: null,
    assignedDate: null,
    usedStatus: false,
    usedBy: null,
    usedDate: null,
    price: null,
    invoiceId: null,
  },
  {
    docId: "x-0003",
    createdAt: "2024-02-24T14:15:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "manager",
    assignedBy: "admin",
    assignedDate: "2024-02-24T14:15:00.000Z",
    usedStatus: true,
    usedBy: "manager",
    usedDate: "2024-02-24T14:15:00.000Z",
    price: 3500,
    invoiceId: 90272709,
  },
  {
    docId: "x-0004",
    createdAt: "2024-02-23T16:45:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-23T16:45:00.000Z",
    usedStatus: false,
    usedBy: null,
    usedDate: null,
    price: null,
    invoiceId: null,
  },
  {
    docId: "x-0005",
    createdAt: "2024-02-22T11:30:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-22T11:30:00.000Z",
    usedStatus: true,
    usedBy: "moderator",
    usedDate: "2024-02-22T11:30:00.000Z",
    price: 4800,
    invoiceId: 90272710,
  },
  {
    docId: "x-0006",
    createdAt: "2024-02-21T09:30:00.000Z",
    createdBy: "admin",
    assigned: false,
    assignedTo: null,
    assignedBy: null,
    assignedDate: null,
    usedStatus: false,
    usedBy: null,
    usedDate: null,
    price: null,
    invoiceId: null,
  },
  {
    docId: "x-0007",
    createdAt: "2024-02-20T13:45:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "manager",
    assignedBy: "admin",
    assignedDate: "2024-02-20T13:45:00.000Z",
    usedStatus: true,
    usedBy: "manager",
    usedDate: "2024-02-20T13:45:00.000Z",
    price: 3800,
    invoiceId: 90272711,
  },
  {
    docId: "x-0008",
    createdAt: "2024-02-19T15:30:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-19T15:30:00.000Z",
    usedStatus: false,
    usedBy: null,
    usedDate: null,
    price: null,
    invoiceId: null,
  },
  {
    docId: "x-0009",
    createdAt: "2024-02-18T12:15:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-18T12:15:00.000Z",
    usedStatus: true,
    usedBy: "moderator",
    usedDate: "2024-02-18T12:15:00.000Z",
    price: 4900,
    invoiceId: 90272712,
  },
  {
    docId: "x-0010",
    createdAt: "2024-02-17T10:30:00.000Z",
    createdBy: "admin",
    assigned: false,
    assignedTo: null,
    assignedBy: null,
    assignedDate: null,
    usedStatus: false,
    usedBy: null,
    usedDate: null,
    price: null,
    invoiceId: null,
  },
]

const usageListDummyData = [
  {
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
  },
  {
    assignedTo: "manager",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
  },
  {
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
  },
  {
    assignedTo: "manager",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
  },
  {
    assignedTo: "moderator",
    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
  },
]

const invoiceDummyData = [
  {
    rowNumber: 1,
    itemCode: 123456789123456,
    description: "test",
    unitPrice: 5000,
    qty: 5,
    totalPrice: 25000,
    lineTotal: 22500,
    discount: "10%",
    petName: "pet1",
    petId: 123,
  },
  {
    rowNumber: 2,
    itemCode: 123456789123456,
    description: "test",
    unitPrice: 600,
    qty: 10,
    totalPrice: 6000,
    lineTotal: 5000,
    discount: 1000,
  },
  {
    rowNumber: 3,
    itemCode: 123456789123456,
    description: "test",
    unitPrice: 500,
    qty: 5,
    totalPrice: 2500,
    lineTotal: 2500,
    discount: 0,
    petName: "",
    petId: 0,
  },
]

const invoicePaymentSummary = [
  { amount: 200, paymentMethod: "Cash", date: "2024-02-27", refNo: "1234" },
  {amount: 50, paymentMethod: "card", date: "2024-0822", refNo: "1234"},
  {amount: 13, paymentMethod: "cheque", date: "2024-05-26", refNo: "1234"},
  {amount: 60, paymentMethod: "bankTransfer", date: "2024-09-26", refNo: "1234"}
]
export { documentsListDummyData, usageListDummyData, invoiceDummyData, invoicePaymentSummary }
