import React, { useState, useEffect } from "react"

import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { postNewDocument } from "store/actions"

const CreatePaper = props => {
  const { modal, toggle } = props

  const dispatch = useDispatch()

  const [usersList, setUsersList] = useState([])
  const [formRows, setFormRows] = useState([{ id: 1 }])
  const [formValues, setFormValues] = useState({
    values: { paperDocId1: "" },
    errors: { paperDocId1: Yup.string().required("Paper Id is required") },
  })
  const [currentRowKey, setCurrentRowKey] = useState({ key: 0, inputType: "" })
  const [addRowError, setAddRowError] = useState(false)

  const fetchUsersList = useSelector(state => state.DocumentsList.usersList)

  useEffect(() => { fetchUsersList &&  setUsersList(fetchUsersList)}, [fetchUsersList])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: formValues.values,
    documentCode: Yup.string().required("Please select code."),
    onSubmit: values => {
      // TODO: submit values
      // To submit last row
      const formToSend = onAddFormRow(true)
      const documentList = formToSend.slice(1)
      documentList.map((row) => delete row.id)
      documentList.map((row) => !row.selectedUser && (row.selectedUser = ""))
      // const documentToSend = { documentList, userId: localStorage.getItem("userId") }
      // dispatch(postNewDocument(documentToSend))
      console.log("Ready to send", documentList)

      resetForm()
      validation.handleReset()
      toggle()
    },
  })

  const resetForm = () => {
    setFormRows([{ id: 1 }])
    setFormValues({
      values: { paperDocId1: "" },
      errors: { paperDocId1: Yup.string().required("Paper Id is required") },
    })

    validation.resetForm()
  }

  // make sure when validation values changed formValues change too
  useEffect(() => {
    setFormValues(prevState => ({
      ...prevState,
      values: validation.values,
    }))
    setAddRowError(false)

    // update formRows when validation values change
    if (formRows.length > 1) {
      const formRowsCpy = [...formRows]
      formRowsCpy.map((row, index) =>
        index === currentRowKey.key && currentRowKey.inputType === "paperDocId"
          ? (row.documentCode = validation.values[`paperDocId${index}`])
          : index === currentRowKey.key &&
            currentRowKey.inputType === "selectedUser" &&
            (row.selectedUser = validation.values[`selectedUser${index}`])
      )
      setFormRows(formRowsCpy)
    }
    // to remove error class
    const nextDoc = formRows.length + 1
    const inputElement = document.querySelector(
      `input[name="paperDocId${nextDoc - 1}"]`
    )
    if (inputElement) {
      inputElement.classList.remove("is-invalid")
    }
  }, [validation.values, currentRowKey])

  const onAddFormRow = (returnValue = false) => {
    const nextDoc = formRows.length + 1
    // Check if Doc Id
    if (validation.values[`paperDocId${nextDoc - 1}`] === "") {
      const inputElement = document.querySelector(
        `input[name="paperDocId${nextDoc - 1}"]`
      )
      if (inputElement) {
        // Add the specified class to the input element
        inputElement.classList.add("is-invalid")
      }
      setAddRowError(true)
      return
    }
    const modifiedRows = [...formRows]
    modifiedRows.push({
      id: Math.random(),
      documentCode: validation.values[`paperDocId${nextDoc - 1}`],
      selectedUser: validation.values[`selectedUser${nextDoc - 1}`],
    })
    setFormRows(modifiedRows)
    // Only return value if last row modified without click add new paper btn
    if (returnValue) {
      return modifiedRows
    }

    setFormValues(prevState => ({
      values: {
        ...prevState.values,
        [`paperDocId${nextDoc}`]: "",
      },
      errors: {
        ...prevState.errors,
        [`paperDocId${nextDoc}`]: Yup.string().required("Paper Id is required"),
      },
    }))
  }


  const onDeleteFormRow = id => {
    if (id !== 1) {
      let modifiedRows = [...formRows]
      const index = modifiedRows.findIndex(x => x["id"] === id)

      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setFormRows(modifiedRows)

      const removeObjectPropertyByIndex = (object, index) => {
        if (index >= 0 && index < Object.keys(object).length) {
          const propertyToRemove = Object.keys(object)[index]
          delete object[propertyToRemove]
        }
        return object
      }
      let values = { ...formValues.values },
        errors = { ...formValues.errors }

      const newValues = removeObjectPropertyByIndex(values, index)
      const newErrors = removeObjectPropertyByIndex(errors, index)
      setFormValues({ values: newValues, errors: newErrors })
    }
  }

  const handleDocumetChange = (value, key) => { validation.setFieldValue(`paperDocId${key}`, value) }

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        Add Paper Id
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Row className="gap-2">
            {(formRows || []).map((formRow, key) => (
              <Row key={key} className="align-items-start">
                <Col className="mb-3">
                  <Label className="form-label">Document Id</Label>
                  <Input
                    name={`paperDocId${key + 1}`}
                    type="text"
                    placeholder="Insert New Paper Document Id"
                    onChange={e => {
                      setCurrentRowKey({
                        key: key + 1,
                        inputType: "paperDocId",
                      })
                      handleDocumetChange(e.target.value, key + 1)
                    }}
                    onBlur={validation.handleBlur}
                    value={validation.values[`paperDocId${key + 1}`] || ""}
                    invalid={
                      validation.touched[`paperDocId${key + 1}`] &&
                      validation.errors[`paperDocId${key + 1}`]
                    }
                  />

                </Col>

                <Col className="mb-3">
                  <Label className="form-label">User</Label>
                  <Select
                    value={
                      validation.values[`selectedUser${key + 1}`] &&
                      validation.values[`selectedUser${key + 1}`] !== ""
                        ? usersList.find(function (option) {
                            return option.id ===
                              Number(
                                validation.values[`selectedUser${key + 1}`].id
                              )
                              ? validation.values[`selectedUser${key + 1}`]
                              : ""
                          })
                        : ""
                    }
                    onChange={e => {
                      validation.setFieldValue(`selectedUser${key + 1}`, e.id)
                      setCurrentRowKey({
                        key: key + 1,
                        inputType: "selectedUser",
                      })
                    }}
                    options={usersList}
                    getOptionLabel={list => list["name"]}
                    getOptionValue={list => list["id"]}
                    className="select2-selection"
                    type="select"
                  />
                </Col>

                <Col xs="auto" className="align-self-center">
                  <div className="d-grid">
                    <button
                      className={`btn btn-danger py-1 ${
                        validation.touched[`paperDocId${key + 1}`] &&
                        validation.errors[`paperDocId${key + 1}`]
                          ? "mb-1"
                          : "mt-2"
                      }`}
                      onClick={() => onDeleteFormRow(formRow.id)}
                    >
                      <i className="mdi mdi-delete font-size-16"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            ))}
            {addRowError && (
              <Row>
                <Col xs={12}>
                  <span style={{ color: "#cd0000" }}>
                    Please add document code.
                  </span>
                </Col>
              </Row>
            )}
            <Col xs="auto">
              <button
                className="btn btn-success btn-label mx-1"
                onClick={e => {
                  e.preventDefault()
                  onAddFormRow()
                }}
              >
                  <i className="bx bx-plus label-icon"></i> Add New Paper
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex gap-2 mt-5">
                <button
                  type="submit"
                  className="btn btn-primary btn-label mx-1 mb-2"
                >
                    <i className="bx bx-check label-icon"></i>
                    Submit
                </button>
                <button
                  onClick={() => {resetForm(); toggle()}}
                  className="btn btn-outline-primary btn-label mx-1 mb-2"
                >
                  <>
                    <i className="bx bx-x label-icon"></i>
                    Cancel
                  </>
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}
export default CreatePaper
