import React, { useContext } from "react"

import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import Select from "react-select"

import { PaymentSummaryContext } from "pages/add-invoice/context/paymentSummaryContext"

const sourceOfRecordOptions = [
  "Select...",
  "Old ERP",
  "New ERP",
  "Client Payment",
]

const SourceOfRecordModel = props => {
  const { isOpen, toggle, rowData } = props

  const paymentSummaryCtx = useContext(PaymentSummaryContext)
  const { updateSourceOfRecordHandler } = paymentSummaryCtx

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} tag="h4">
        Source Of Record
      </ModalHeader>
      <ModalBody>
        <Row>
          <Label className="form-label">Source Of Record</Label>
          <Select
            value={{
              value: rowData.sourceOfRecord
                ? rowData.sourceOfRecord
                : "Select...",
              label: rowData.sourceOfRecord
                ? rowData.sourceOfRecord
                : "Select...",
            }}
            onChange={selectedOption => {
              if (selectedOption.value === "Select...") {
                updateSourceOfRecordHandler(rowData, "")
              } else {
                updateSourceOfRecordHandler(rowData, selectedOption.value)
              }
            }}
            options={sourceOfRecordOptions.map(one => ({
              value: one,
              label: one,
            }))}
            className="select2-selection"
          />
        </Row>
        <Row className="mt-4">
          <Col>
            <div className="d-flex gap-2">
              <button
                onClick={toggle}
                className={`btn btn-primary save-customer`}
              >
                Save
              </button>
              <button
                onClick={toggle}
                type="button"
                className="btn btn-cancel text-primary"
              >
                Close
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default SourceOfRecordModel
