const invoicesListDummyData = [
  {
    S_no: 1,
    invoice_serial: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Onsite Purchase",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: 2,
    invoice_status_name: "Reviewed",
    ticket_status: 1,
    memmbership: "golden",
    saleType: "agel",
    Customer_phone: "0123456789",
    customer_email: "RqT6S@example.com",
    hasPaymentSummary: true,
    systemUser: "admin",
  },
  {
    S_no: 2,
    invoice_serial: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Market Place Purchased",
    market_Place_Purchased: "talabat",
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: 1,
    ticket_status: 2,
    invoice_status_name: "Reviewed",
    memmbership: "platinum",
    saleType: "cash",
    Customer_phone: "01232456789",
    customer_email: "RqT6S@example.com",
    hasPaymentSummary: false,
    systemUser: "admin",


  },
  {
    S_no: 3,
    invoice_serial: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Market Place Purchased",
    market_Place_Purchased: "avey",
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: 2,
    invoice_status_name: "Reviewed",
    ticket_status: 3,
    memmbership: "golden",
    saleType: "agel",
    Customer_phone: "0123456789",
    customer_email: "RqT6S@example.com",
    hasPaymentSummary: true,
    systemUser: "cashier",



  },
  {
    S_no: 4,
    invoice_serial: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Onsite Purchase",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: 2,
    invoice_status_name: "Reviewed",
    ticket_status: 1,
    memmbership: "other",
    saleType: "agel",
    Customer_phone: "0123456789",
    customer_email: "RqT6S@example.com",

    hasPaymentSummary: false,
    systemUser: "admin",



  },
  {
    S_no: 5,
    invoice_serial: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Self Pickup",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: 3,
    invoice_status_name: "Approved",
    ticket_status: 3,
    memmbership: "golden",
    saleType: "cash",
    Customer_phone: "0123433389",
    customer_email: "RqT6S@example.com",

    hasPaymentSummary: false,
    systemUser: "admin",



  },
  {
    S_no: 6,
    invoice_serial: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Self Pickup",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: 4,
    invoice_status_name: "Closed",
    ticket_status: 2,
    memmbership: "platinum",
    saleType: "agel",
    Customer_phone: "01233333389",
    customer_email: "RqT6S@example.com",

    hasPaymentSummary: true,
    systemUser: "sadmin",


  },
  {
    S_no: 7,
    invoice_serial: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Self Pickup",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: 1,
    invoice_status_name: "Raised",
    ticket_status: 1,
    memmbership: "golden",
    saleType: "agel",
    Customer_phone: "015556789",
    customer_email: "RqT6S@example.com",

    hasPaymentSummary: false,
    systemUser: "superadmin",



  },
  {
    S_no: 8,
    invoice_serial: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Delivery",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: 2,
    invoice_status_name: "Raised",
    ticket_status: 1,
    memmbership: "golden",
    saleType: "cash",
    Customer_phone: "0123444489",
    customer_email: "RqT6S@example.com",

    hasPaymentSummary: false,
    systemUser: "admin",


  },
  {
    S_no: 9,
    invoice_serial: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Delivery",
    market_Place_Purchased: null,
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: 2,
    invoice_status_name: "Reviewed",
    ticket_status: 3,
    memmbership: "golden",
    saleType: "agel",
    Customer_phone: "01234532229",
    customer_email: "RqT6S@example.com",

    hasPaymentSummary: true,
    systemUser: "user",




  },
  {
    S_no: 10,
    invoice_serial: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Market Place Purchased",
    market_Place_Purchased: "snoonu",
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: 4,
    ticket_status: 1,
    invoice_status_name: "Raised",
    memmbership: "platinum",
    saleType: "agel",
    Customer_phone: "0123456789",
    customer_email: "RqT6S@example.com",

    hasPaymentSummary: true,
    systemUser: "admin",


  },
  {
    S_no: 11,
    invoice_serial: "MINV-00001-e",
    Date: "17/04/2023",
    time: "12:30 PM",
    prepared_by: "tonny",
    Customer_Name: "jone doe",
    Customer_No: 5,
    updated_at: "17/04/2023",
    updated_by: "admin",
    purchase_Type: "Market Place Purchased",
    market_Place_Purchased: "rafeeq",
    Total_Price: 1000,
    Total_Discount: 100,
    Line_Total: 900,
    invoice_status: 3,
    invoice_status_name: "Approved",
    ticket_status: 2,
    memmbership: "golden",
    saleType: "agel",
    Customer_phone: "0123456789",
    customer_email: "RqT6S@example.com",
    hasPaymentSummary: true,
    systemUser: "sadmin",


  },
]

const invoicesStatus = [
  {
    id: 1,
    name: "Raised",
  },
  {
    id: 2,
    name: "Reviewed",
  },
  {
    id: 3,
    name: "Approved",
  },
  {
    id: 4,
    name: "Closed",
  },

]

const ticketStatus = [
  {
    id: 1,
    name: "Raised",
  },
  {
    id: 2,
    name: "Reviewed",
  },
  {
    id: 3,
    name: "Approved",
  },
  {
    id: 4,
    name: "Closed",
  },

]
export { invoicesListDummyData, invoicesStatus, ticketStatus }
