import SourceOfRecordModel from "pages/add-invoice/notInPrint/sourceOfRecordModel"
import React, { useState } from "react"
import { UncontrolledTooltip } from "reactstrap"

const PaymentSummary = props => {
  const { rowData } = props
  const [showModal, setShowModal] = useState({ state: false, rowData: rowData })

  const toggleModalHandler = () => {
    setShowModal(prevState => ({
      state: !prevState.state,
      rowData: rowData,
    }))
  }

  const openModelHandler = () => {
    if (rowData.recivedNow) return

    toggleModalHandler()
  }

  return (
    <>
      <div
        style={{
          width: "30px",
          marginLeft: "10px",
          paddingTop: "15px",
          paddingBottom: "6px",
          position: "relative",
          zIndex: 1,
          opacity: rowData.recivedNow ? "0" : "1",
          cursor: rowData.recivedNow ? "default" : "pointer",
        }}
        onClick={openModelHandler}
      >
        <i
          className={`mdi mdi-information-outline mdi-18px ${
            rowData.sourceOfRecord ? "text-success" : "text-danger"
          }`}
          id={`info-${rowData.rowNumber}`}
        ></i>
        {rowData.recivedNow ? (
          <></>
        ) : (
          <UncontrolledTooltip
            placement="top"
            target={`info-${rowData.rowNumber}`}
          >
            Insert Source Of Record
          </UncontrolledTooltip>
        )}
      </div>
      <SourceOfRecordModel
        isOpen={showModal.state}
        toggle={toggleModalHandler}
        rowData={showModal.rowData}
      />
    </>
  )
}

export default PaymentSummary
