import React, { useState, createContext } from "react"

let initialPaperId = null,
  initialPapersIds = [
    "Select...",
    "MINV-0001-e",
    "MINV-0002-e",
    "MINV-0003-e",
    "MINV-0004-e",
    "MINV-0005-e",
  ]

export const DocumentIdContext = createContext({
  paperId: initialPaperId,
  papersIds: initialPapersIds,
  updatePaperIdHandler: paperId => {},
})

const DocumentIdContextProvider = props => {
  const [paperId, setPaperId] = useState(initialPaperId)
  const [papersIds, setPapersIds] = useState(initialPapersIds)

  const updatePaperIdHandler = paperId => {
    if (paperId === "Select...") {
      setPaperId(null)
    } else {
      setPaperId(paperId)
    }
  }

  return (
    <DocumentIdContext.Provider
      value={{
        paperId: paperId,
        papersIds,
        updatePaperIdHandler: updatePaperIdHandler,
      }}
    >
      {props.children}
    </DocumentIdContext.Provider>
  )
}

export default DocumentIdContextProvider
