import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import { Input } from "reactstrap"
import * as Yup from "yup"
import { getInvoicesStatus } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import ConfirmationModal from "../ConfirmationModal"

const UpdateInvoiceStatus = props => {
  const { S_no, invoice_status } = props

  const dispatch = useDispatch()
  const [confirmationModal, setConfirmationModal] = useState(false)

  const invoicesStatus = useSelector(state => state.Invoices.invoicesStatus)

  useEffect(() => {
    dispatch(dispatch(getInvoicesStatus()))
  }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Please select Status."),
    }),
    onSubmit: values => {
      //
    },
  })

  React.useEffect(() => {
    validation.setFieldValue("status", invoice_status)
  }, [invoice_status])

  const toggle = () => confirmationModal ? setConfirmationModal(false) : setConfirmationModal(true)
  const handleChange = e => {
    validation.setFieldValue("status", e.target.value)
    toggle()
  }

  const cancelChange = () => {
    validation.setFieldValue("status", invoice_status)
  }

  

  return (
    <>
      <Input
        name="status"
        type="select"
        onChange={e => handleChange(e)}
        onBlur={validation.handleBlur}
        value={validation.values.status || ""}
        invalid={
          validation.touched.status && validation.errors.status ? true : false
        }
      >
        <option value="" disabled>
          Select Status
        </option>
        {invoicesStatus.map((status, index) => (
          <option key={index} value={status.id}>
            {status.name}
          </option>
        ))}
      </Input>
      <ConfirmationModal
        toggle={toggle}
        modal={confirmationModal}
        confirmAction="invoiceStatus"
        cancelChange={cancelChange}
        S_no={S_no}
        newValue={validation.values.status}
        close={() => setConfirmationModal(false)}
      />
    </>
  )
}

export default UpdateInvoiceStatus
