import TableContainer from "components/Common/TableContainer"
import React, { useEffect, useMemo, useState } from "react"
import { Badge, Card, CardBody, Col, Modal, ModalHeader, Row } from "reactstrap"
import { convertDate } from "pages/add-paper/utils/convertDate"

import { usageListDummyData } from "pages/data"


import {  useSelector } from "react-redux"

const AssignDetails = props => {
  const { modal, toggle, assignData } = props

  const fetchedDocumentAssignDetails  = useSelector(state => state.DocumentsList.documentAssignDetails)
  const [assignDetails, setAssignDetails] = useState([])



  useEffect(() => {
    if (fetchedDocumentAssignDetails) {
      setAssignDetails(fetchedDocumentAssignDetails)
    }
  }, [fetchedDocumentAssignDetails])
  const columns = useMemo(
    () => [
      {
        Header: "Assigned By",
        accessor: "assignedBy",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Assigned To",
        accessor: "assignedTo",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Assigned Date",
        accessor: "assignedDate",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value ? convertDate(cellProps.value) : ""}</>
        },
      },
    ],
    []
  )

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        Assign Details
      </ModalHeader>
      <Row>
        <Col lg="12" className="mt-2 mx-3">
          <Row>
            <Col lg="6">
              <div className="flex justify-content-between mb-3">
                <span style={{ fontWeight: "600" }}>
                  Current Assign Status:{" "}
                </span>
                <span style={{ fontWeight: "500" }}>
                  <Badge
                    className={`p-2 
                             ${
                               assignData.assigned ? "bg-success" : "bg-danger"
                             }`}
                  >
                    {assignData.assigned ? "Assigned" : "Not Assigned"}
                  </Badge>
                </span>
              </div>
              <div className="flex justify-content-between mb-3">
                <span style={{ fontWeight: "600" }}>Last Assigned Date: </span>
                <span style={{ fontWeight: "500" }}>
                  {convertDate(assignData.assignedDate)}
                </span>
              </div>
            </Col>
            <Col lg="6">
              <div className="flex justify-content-between mb-3">
                <span style={{ fontWeight: "600" }}>Last Assigned By: </span>
                <span style={{ fontWeight: "500" }}>
                  {assignData.assignedBy}
                </span>
              </div>
              <div className="flex justify-content-between mb-3">
                <span style={{ fontWeight: "600" }}>Last Assigned To: </span>
                <span style={{ fontWeight: "500" }}>
                  {assignData.assignedToName}
                </span>
              </div>
            </Col>
            {assignData.usedStatus && <Col lg="6">
              <div className="flex justify-content-between mb-3">
                <span style={{ fontWeight: "600" }}>usedBy: </span>
                <span style={{ fontWeight: "500" }}>
                  {assignData.usedBy}
                </span>
              </div>
            </Col>}
          </Row>
        </Col>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                columns={columns}
                data={assignDetails}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={5}
                allListSize={assignDetails.length}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Modal>
  )
}

export default AssignDetails
