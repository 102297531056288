import React from "react"
import ReactToPrint from "react-to-print"

const PrintButton = props => {
  return (
    <div
      style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}
    >
      <ReactToPrint
        trigger={() => (
          <button type="button" className="btn btn-primary btn-label mx-1 mb-2">
            <>
              <i className="bx bxs-printer label-icon"></i> Print
            </>
          </button>
        )}
        content={() => props.printContentRef}
      />
    </div>
  )
}

export default PrintButton
