import TableContainer from "components/Common/TableContainer"
import React, { useEffect, useMemo } from "react"
import { Card, CardBody, Col, Modal, ModalHeader, Row, Table } from "reactstrap"
import classes from "./index.module.css"
import { useDispatch, useSelector } from "react-redux"
import { getInvoiceDetails } from "store/actions"



const InvoiceModal = props => {
  const { modal, toggle, invoiceDetails } = props
  const invoiceDetail = useSelector(state => state.Invoices.invoiceDetail)
  const dispatch = useDispatch()

  useEffect(() => {
    console.log(invoiceDetails)
    dispatch(getInvoiceDetails(invoiceDetails.S_no))
  }, [invoiceDetails])
  useEffect(() => {
    console.log(invoiceDetail)
  }, [invoiceDetail])
  const columns = useMemo(
    () => [
      {
        Header: "Row Number",
        accessor: "rowNumber",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Item Code",
        accessor: "itemCode",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Pet Name",
        accessor: "petName",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Pet Id",
        accessor: "petId",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Unit Price",
        accessor: "unitPrice",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Qty",
        accessor: "qty",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Total Price",
        accessor: "totalPrice",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Discount",
        accessor: "Discount",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Line Total",
        accessor: "lineTotal",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
    ],
    []
  )

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl" fullscreen="lg">
      <ModalHeader toggle={toggle} tag="h4">
        Invoice No. {invoiceDetails ? invoiceDetails.invoice_serial : ""}
      </ModalHeader>
      <div className="table-responsive">
        <Table className={`table mb-0 table-bordered ${classes.tableSmall}`}>
          <tbody>
            <tr>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                Sales Type:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.saleType : ""}
              </td>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                Prepared By:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.prepared_by : ""}
              </td>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                System user:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.systemUser : ""}
              </td>
            </tr>

            <tr>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                Cust. No:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.Customer_No : ""}
              </td>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                Cust. Name:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.Customer_Name : ""}
              </td>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                Mobile:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.Customer_phone : ""}
              </td>
            </tr>

            <tr>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                Mobile:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.Customer_phone : ""}
              </td>

              <th scope="row" className={`text-capitalize ${classes.th}`}>
                Email:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.customer_email : ""}
              </td>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                MemberShip:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.memmbership : ""}
              </td>
            </tr>
            <tr>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                Date:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.Date : ""}
              </td>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                time:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.time : ""}
              </td>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                updated at:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.updated_at : ""}
              </td>
            </tr>
            <tr>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                Purch. type:
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails ? invoiceDetails.purchase_Type : ""}
              </td>
              <th scope="row" className={`text-capitalize ${classes.th}`}>
                {invoiceDetails.market_Place_Purchased && "Market place:"}
              </th>
              <td className={`bg-white ${classes.td}`}>
                {invoiceDetails && invoiceDetails.market_Place_Purchased
                  ? invoiceDetails.market_Place_Purchased
                  : ""}
              </td>
              <th scope="row" className={`text-capitalize ${classes.th}`}></th>
              <td className={`bg-white ${classes.td}`}>
                {/* {invoiceDetails ? invoiceDetails.updated_at : ""} */}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                columns={columns}
                data={invoiceDetail}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={5}
                allListSize={invoiceDetail ? invoiceDetail.length : 0}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Modal>
  )
}

export default InvoiceModal
