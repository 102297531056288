import {
  GET_DOCUMENTS_LIST,
  GET_DOCUMENTS_LIST_SUCCESS,
  GET_DOCUMENTS_LIST_FAIL,
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,
  POST_DOCUMENT_ASSIGNTO,
  POST_DOCUMENT_ASSIGNTO_SUCCESS,
  POST_DOCUMENT_ASSIGNTO_FAIL,
  GET_DOCUMENT_ASSIGN_DETAILS,
  GET_DOCUMENT_ASSIGN_DETAILS_SUCCESS,
  GET_DOCUMENT_ASSIGN_DETAILS_FAIL,
  POST_NEW_DOCUMENT,
  POST_NEW_DOCUMENT_SUCCESS,
  POST_NEW_DOCUMENT_FAIL
} from "./actionTypes"

const INIT_STATE = {
  documentsList: [],
  error: {},
  getDocumentsLoading: false,

  usersList: [],
  userError: {},
  getUsersLoading: false,

  editDocAssignTo: "",
  editDocAssignToError: {},
  editDocAssignToLoading: false,

  documentAssignDetails: [],
  documentAssignDetailsError: {},
  documentAssignDetailsLoading: false,

  addNewDocumentres: "",
  addNewDocumentError: {},
  addNewDocumentLoading: false,
}

const DocumentsList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_LIST:
      return {
        ...state,
        getDocumentsLoading: true,
      }
    case GET_DOCUMENTS_LIST_SUCCESS:
      return {
        ...state,
        documentsList: action.payload,
        getDocumentsLoading: false,
      }
    case GET_DOCUMENTS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        getDocumentsLoading: false,
      }

    case GET_USERS_LIST:
      return {
        ...state,
        getUsersLoading: true,
      }
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        getUsersLoading: false,
      }
    case GET_USERS_LIST_FAIL:
      return {
        ...state,
        userError: action.payload,
        getUsersLoading: false,
      }

    case POST_DOCUMENT_ASSIGNTO:
      return {
        ...state,
        editDocAssignToLoading: true,
      }
    case POST_DOCUMENT_ASSIGNTO_SUCCESS:
      return {
        ...state,
        editDocAssignTo: action.payload,
        editDocAssignToLoading: false,
      }
    case POST_DOCUMENT_ASSIGNTO_FAIL:
      return {
        ...state,
        editDocAssignToError: action.payload,
        editDocAssignToLoading: false,
      }

      case GET_DOCUMENT_ASSIGN_DETAILS:
      return {
        ...state,
        documentAssignDetailsLoading: true,
        documentAssignDetails: {},
        documentAssignDetailsError: {},
      }
    case GET_DOCUMENT_ASSIGN_DETAILS_SUCCESS:
      return {
        ...state,
        documentAssignDetails: action.payload,
        documentAssignDetailsError: {},
        documentAssignDetailsLoading: false,
      }
    case GET_DOCUMENT_ASSIGN_DETAILS_FAIL:
      return {
        ...state,
        documentAssignDetails: [],
        documentAssignDetailsError: action.payload,
        documentAssignDetailsLoading: false,
      }

      case POST_NEW_DOCUMENT:
      return {
        ...state,
        addNewDocumentLoading: true,
        addNewDocumentres: "",
        addNewDocumentError: {},
      }

    case POST_NEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        addNewDocumentres: action.payload,
        addNewDocumentLoading: false,
      }
    case POST_NEW_DOCUMENT_FAIL:
      return {
        ...state,
        addNewDocumentError: action.payload,
        addNewDocumentLoading: false,
      }
      
    default:
      return state
  }
}

export default DocumentsList
