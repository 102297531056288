export const GET_DOCUMENTS_LIST = "GET_DOCUMENTS_LIST"
export const GET_DOCUMENTS_LIST_SUCCESS = "GET_DOCUMENTS_LIST_SUCCESS"
export const GET_DOCUMENTS_LIST_FAIL = "GET_DOCUMENTS_LIST_FAIL"

export const GET_USERS_LIST = "GET_USERS_LIST"
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS"
export const GET_USERS_LIST_FAIL = "GET_USERS_LIST_FAIL"

export const POST_DOCUMENT_ASSIGNTO = "POST_DOCUMENT_ASSIGNTO"
export const POST_DOCUMENT_ASSIGNTO_SUCCESS = "POST_DOCUMENT_ASSIGNTO_SUCCESS"
export const POST_DOCUMENT_ASSIGNTO_FAIL = "POST_DOCUMENT_ASSIGNTO_FAIL"

export const GET_DOCUMENT_ASSIGN_DETAILS = "GET_DOCUMENT_ASSIGN_DETAILS"
export const GET_DOCUMENT_ASSIGN_DETAILS_SUCCESS = "GET_DOCUMENT_ASSIGN_DETAILS_SUCCESS"
export const GET_DOCUMENT_ASSIGN_DETAILS_FAIL = "GET_DOCUMENT_ASSIGN_DETAILS_FAIL"

export const POST_NEW_DOCUMENT = "POST_NEW_DOCUMENT"
export const POST_NEW_DOCUMENT_SUCCESS = "POST_NEW_DOCUMENT_SUCCESS"
export const POST_NEW_DOCUMENT_FAIL = "POST_NEW_DOCUMENT_FAIL"

