import React, { useState, useEffect } from "react"

import { Button, Card, CardBody, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import CreatePaper from "pages/add-paper/createPaper"
import DocumentsList from "pages/add-paper/documentsList"
import { useDispatch } from "react-redux"
import { getUsersList } from "store/actions"

const Paper = () => {
  document.title = "Dragon | add paper id"
  const dispatch  = useDispatch()
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(prevState => !prevState)

  useEffect(() => {
    dispatch(getUsersList())
  }, [])

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Invoices" breadcrumbItem="Add Document Id" />
        <Row>
          <Card>
            <CardBody
              className="d-flex flex-row flex-wrap align-items-start gap-2 justify-content-end mt-3"
              style={{ marginRight: "25px" }}
            >
              <div>
                <Button
                  color="primary"
                  className="btn-rounded"
                  onClick={() => {
                    toggle()
                  }}
                >
                  <i className="mdi mdi-plus" /> Add Document
                </Button>
              </div>

              <CreatePaper modal={modal} toggle={toggle} />
            </CardBody>
            <DocumentsList />
          </Card>
        </Row>
      </Container>
    </div>
  )
}

export default Paper
