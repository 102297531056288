import React, { useEffect, useContext } from "react"
import classes from "../index.module.scss"
import * as Yup from "yup"
import { useFormik } from "formik"
import { FormFeedback, Input } from "reactstrap"

import { PetContext } from "pages/add-invoice/context/petContext"

const SinglePetTableRow = props => {
  const { rowNumber, index, topTabelRowsData } = props

  const petContext = useContext(PetContext)
  const { updateTabelDataHandler, activeRow, updateIsEmptyHandler } = petContext


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      rowNumber: rowNumber,
      aboveNo: "",
      petName: "",
      petId: "",
    },
    validationSchema: Yup.object({
        aboveNo: Yup.number().required("Please Enter Recived Now"),
        petName: Yup.string().required("Please Enter Amount"),
        petId: Yup.string().required("Please Enter Method"),
    }),
    onSubmit: values => {
      //
    },
  })

  useEffect(() => {
    let empty = false
    Object.keys(validation.values).forEach(function(key, index) {
      validation.values[key] === "" && (empty = true) 
    });
    
    if(empty) { updateIsEmptyHandler(empty); return }
    updateTabelDataHandler({
      rowNumber: validation.values.rowNumber,
      aboveNo: validation.values.aboveNo,
      petName: validation.values.petName,
      petId: validation.values.petId,
    })
    updateIsEmptyHandler(empty)

  }, [validation.values])


  return (
    <div style={{ display: "flex", paddingTop: "12px" }}>
      <div style={{ width: "30px", marginLeft: "53px" }}>
        <span className={classes.transparent_input}>
          {validation.values.rowNumber}
        </span>
      </div>
      <div style={{ width: "80px", marginLeft: "-5px" }}>
        <select
          id="aboveNo"
          name="aboveNo"
          className={`${classes.transparent_input} ${
            index + 1 > activeRow && "opacity-0"
          }`}
          style={{ width: "80px" }}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          disabled={index + 1 > activeRow}
        >
          <option value="">Select ...</option>
          {topTabelRowsData && topTabelRowsData.length ? (
            topTabelRowsData.map((one, index) => (
              <option key={index} value={one.rowNumber}>
                {one.rowNumber}
              </option>
            ))
          ) : (
            <></>
          )}
        </select>
      </div>
      <div style={{ width: "130px", marginLeft: "15px" }}>
        <Input
          id="petName"
          name="petName"
          type="text"
          className={classes.transparent_input}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.petName || ""}
          disabled={index + 1 > activeRow}
          invalid={
            validation.touched.petName && validation.errors.petName
              ? true
              : false
          }
          autocomplete="off"
        />
      </div>
      <div style={{ width: "160px", marginLeft: "20px" }}>
        <Input
          id="petId"
          name="petId"
          type="text"
          className={classes.transparent_input}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.petId || ""}
          disabled={index + 1 > activeRow}
          invalid={
            validation.touched.petId && validation.errors.petId ? true : false
          }
          autocomplete="off"
        />
      </div>
    </div>
  )
}

export default SinglePetTableRow
