import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER_DATA, LOGOUT_USER_CLEAR } from "./actionTypes"
import { loginError, loginSuccessData, logoutUserSuccessClear } from "./actions"

import { postLogin } from "../../helpers/backend_helper"

function* loginUserData({ payload: { user, history } }) {
  // Buffalo Login Code

  let userData = {
    username: user.email,
    password: user.password,
  }
  try {
    const response = yield call(postLogin, userData)

    localStorage.setItem("authUser", JSON.stringify(response.data.rs.employee))
    localStorage.setItem("token", response.data.rs.token)
    localStorage.setItem("shiftID", response.data.rs.shiftID)
    yield put(loginSuccessData(response.data.rs.employee))
    history("/products")
  } catch (error) {
    yield put(loginError(error))
  }
}

function* loginSaga() {
    yield takeEvery(LOGIN_USER_DATA, loginUserData);
  }
  
  export default loginSaga;
