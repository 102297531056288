import React, { useContext, useState, useEffect } from "react"
import ReactToPrint from "react-to-print"

import * as Yup from "yup"
import { useFormik } from "formik"

import {
  Badge,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Select from "react-select"

import { DocumentIdContext } from "pages/add-invoice/context/documentIdContext"
let errMessage = "Please Enter New Paper Document Id"
const PrintModel = props => {
  const { isOpen, toggle, printContentRef } = props
  const [showDocId, setShowDocId] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  const DocumentIdCtx = useContext(DocumentIdContext)
  const { paperId, papersIds, updatePaperIdHandler } = DocumentIdCtx

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      paperDocId: "",
    },
    validationSchema: Yup.object({
      paperDocId: Yup.string().required(errMessage),
    }),
    onSubmit: values => {
      // TODO: submit values
    },
  })

  useEffect(() => {
    updatePaperIdHandler(validation.values.paperDocId)
  }, [validation.values.paperDocId])

  useEffect(() => {
    if (paperId) {
      validation.setFieldError("paperDocId", "")
      setShowErrorMessage(false)
    }
  }, [paperId])

  useEffect(() => {
    if (showErrorMessage) {
      validation.setFieldError("paperDocId", errMessage)
    }
  }, [showErrorMessage])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        Print Invoice
      </ModalHeader>
      <ModalBody>
        <Row>
          <Label className="form-label">Paper Document Id</Label>
          <Select
            value={{
              value: paperId ? paperId : "Select...",
              label: paperId ? paperId : "Select...",
            }}
            onChange={selectedOption => {
              updatePaperIdHandler(selectedOption.value)
            }}
            options={papersIds.map(one => ({
              value: one,
              label: one,
            }))}
            className="select2-selection"
          />
          {showErrorMessage ? (
            <span className="text-danger font-size-12 mx-2" type="invalid">
              {errMessage}
            </span>
          ) : null}
        </Row>
        <Row className="mt-4">
          <Col>
            <Badge
              color="primary"
              className="me-1 fw-bold font-size-12 p-1 px-3 "
              style={{ cursor: "pointer" }}
              onClick={() => setShowDocId(prevState => !prevState)}
            >
              The Paper Document id not listed here
            </Badge>
          </Col>
        </Row>
        {showDocId ? (
          <div className="mt-4">
            <Label className="form-label">New Paper Document Id</Label>
            <Input
              name="paperDocId"
              type="text"
              placeholder="Insert New Paper Document Id"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.paperDocId || ""}
              invalid={
                validation.touched.paperDocId && validation.errors.paperDocId
                  ? true
                  : false
              }
            />
            {validation.touched.paperDocId && validation.errors.paperDocId ? (
              <FormFeedback type="invalid">
                {validation.errors.paperDocId}
              </FormFeedback>
            ) : null}
          </div>
        ) : (
          <></>
        )}
        <Row>
          <Col>
            <div className="d-flex gap-2 mt-5">
              {paperId ? (
                <ReactToPrint
                  trigger={() => (
                    <button
                      type="button"
                      className="btn btn-primary btn-label mx-1 mb-2"
                    >
                      <>
                        <i className="bx bxs-printer label-icon"></i> Print
                      </>
                    </button>
                  )}
                  content={() => printContentRef}
                />
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-label mx-1 mb-2"
                  onClick={() => setShowErrorMessage(true)}
                >
                  <>
                    <i className="bx bxs-printer label-icon"></i> Print
                  </>
                </button>
              )}
              <button
                onClick={toggle}
                type="button"
                className="btn btn-primary btn-label mx-1 mb-2"
              >
                <>
                  <i className="bx bx-x label-icon"></i> Cancel
                </>
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default PrintModel
