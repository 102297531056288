import { post as postAuth , get as getAuth } from "./axios_auth_api"
import { post as postNonAuth , get as getNonAuth  } from "./axios_api"

//////// Buffalo Login ////////////
export const postLogin = async (data) => {
    try {
      return await postNonAuth("Login", data).then((response) => response)
    } catch({response}){
      return response;
    }
  }

export const getItems = async () => await getAuth('TblItem/getPage');

export const getItemNotes = async (itemId) => await getAuth(`TblItemNote/getPage?userQuery=fkItemId=${itemId}`);

// Manual invoice document list api
// export const getDocumentList = async () => await getAuth('TblDocument/getPage');

