import { takeEvery, put, call } from "redux-saga/effects"

import {
  GET_DOCUMENTS_LIST,
  GET_USERS_LIST,
  POST_DOCUMENT_ASSIGNTO,
  GET_DOCUMENT_ASSIGN_DETAILS,
  POST_NEW_DOCUMENT
} from "./actionTypes"

import {
  getDocumentsListSuccess,
  getDocumentsListFail,
  getUsersListSuccess,
  getUsersListFail,
  postDocumentAssignToSuccess,
  postDocumentAssignToFail,
  getDocumentAssignDetailsSuccess,
  getDocumentAssignDetailsFail,
  postNewDocumentSuccess,
  postNewDocumentFail
} from "./actions"

// import { getDocumentList } from "helpers/backend_helper"

const responseUser = [
  { name: "SuperAdmin", id: 1 },
  { name: "Admin", id: 2 },
  { name: "subAdmin", id: 3 },
  { name: "manager", id: 4 },
  { name: "moderator", id: 5 },
]

const responseDocList = [
  {
    docId: "x-0001",
    createdAt: "2024-02-26T09:00:00.000Z",
    createdBy: "admin",
    assigned: true,
    assignedTo: 1,
    assignedToName: "SuperAdmin",
    usedStatus: true,
    price: 5200,
    invoiceId: 90272708,

    assignedBy: "admin",
    assignedDate: "2024-02-26T09:00:00.000Z",
    usedBy: "moderator",
    usedDate: "2024-02-26T09:00:00.000Z",
  },
  {
    docId: "x-0002",
    createdAt: "2024-02-25T10:30:00.000Z",
    createdBy: "admin",
    assigned: false,
    assignedTo: null,
    assignedToName: null,
    assignedBy: null,
    assignedDate: null,
    usedStatus: false,
    usedBy: null,
    usedDate: null,
    price: null,
    invoiceId: null,
  },
]

const docAssignDetailsList = id => {
  if (id === 'x-0001') {
    return [
      {
        assignedTo: "moderator",
        assignedBy: "admin",
        assignedDate: "2024-02-26T09:00:00.000Z",
      },
      {
        assignedTo: "manager",
        assignedBy: "admin",
        assignedDate: "2024-02-26T09:00:00.000Z",
      },
    ]
  } else if (id === 'x-0002') {
    return [
      {
        assignedTo: "moderator",
        assignedBy: "admin",
        assignedDate: "2024-02-26T09:00:00.000Z",
      },
      {
        assignedTo: "manager",
        assignedBy: "admin",
        assignedDate: "2024-02-26T09:00:00.000Z",
      },
    ]
  } else {
    return []
  }
}

function* fetchDocumentsList() {
  try {
    // const response = yield call(getDocumentList)
    yield put(getDocumentsListSuccess(responseDocList))
  } catch (error) {
    yield put(getDocumentsListFail(error))
  }
}

function* fetchUsersList() {
  try {
    // const response = yield call(getDocumentList)
    yield put(getUsersListSuccess(responseUser))
  } catch (error) {
    yield put(getUsersListFail(error))
  }
}

function* postDocAssignTo({ payload: data }) {
  try {
    console.log(data)
    const res = 1
    // const response = yield call(postDocAssignToApi, data)
    yield put(postDocumentAssignToSuccess(data))
  } catch (error) {
    yield put(postDocumentAssignToFail(error))
  }
}

function* fetchDocAssignDetails({ payload: data }) {
  try {
    console.log(data)
    const res = docAssignDetailsList(data)
    // const response = yield call(postDocAssignToApi, data)
    yield put(getDocumentAssignDetailsSuccess(res))
  } catch (error) {
    yield put(getDocumentAssignDetailsFail(error))
  }
}

function* postNewDocumentData({ payload: data }) {
  try {
    const res = 1
    // const response = yield call(postDocAssignToApi, data)
    // yield put(postDocumentAssignToSuccess(res))
  } catch (error) {
    yield put(postDocumentAssignToFail(error))
  }
}

function* documentListSaga() {
  yield takeEvery(GET_DOCUMENTS_LIST, fetchDocumentsList)
  yield takeEvery(GET_USERS_LIST, fetchUsersList)
  yield takeEvery(POST_DOCUMENT_ASSIGNTO, postDocAssignTo)
  yield takeEvery(GET_DOCUMENT_ASSIGN_DETAILS, fetchDocAssignDetails)
  yield takeEvery(POST_NEW_DOCUMENT, postNewDocumentData)
}

export default documentListSaga
