import React from "react";
import { Container } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import InvoicesList from "./invoicesList";

const Invoices = () => {
  document.title = "Dragon | invoices list";

  return (
    <div className="page-content">
      <Container fluid>
        {/* <Breadcrumbs title="Invoices" breadcrumbItem={search ? "Invoice" : "Invoices List"} /> */}
        {/* {search ?
          <>
            <Button
              type="button"
              color="primary"
              className="btn btn-primary btn-label mx-4 mb-4"
              onClick={() => navigate(-1)}
            >
              <i className="bx bx-left-arrow label-icon align-middle fs-16 me-2" />
              Back
            </Button>
            <SingleInvoice />
          </>
          : <InvoicesList />
        } */}
        <Breadcrumbs title="Invoices" breadcrumbItem="Invoices List" />
        <InvoicesList />
      </Container >
    </div >
  );
};

export default Invoices;
