import React, { useState } from "react"

import PrintModel from "pages/add-invoice/printInvoice/printModel"

const Submit = props => {
  const [showModal, setShowModal] = useState(false)

  const toggleModalHandler = () => setShowModal(prevState => !prevState)

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <button
          onClick={toggleModalHandler}
          type="button"
          className="btn btn-primary btn-label mx-1 mb-2"
        >
          <>
            <i className="bx bx-check label-icon"></i> Submit
          </>
        </button>
      </div>
      <PrintModel
        isOpen={showModal}
        toggle={toggleModalHandler}
        printContentRef={props.printContentRef}
      />
    </>
  )
}

export default Submit
