import React from "react"

const Header = () => {
  return (
    <div
      className="bg-white text-danger text-center"
      style={{
        width: "125px",
        marginLeft: "1049px",
        border: "1px solid red",
        fontWeight: "bold",
        fontSize: "14px",
        marginTop: "27px",
      }}
    >
      XXXXXXXXX
    </div>
  )
}

export default Header
