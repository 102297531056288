import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  Row,
  Col,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { postInvoicesStatus, postTicketStatus, postDocumentAssignTo } from "store/actions"

const ConfirmationModal = props => {
  const { modal, toggle, confirmAction, cancelChange, S_no, newValue, close } = props

  const dispatch = useDispatch()

  const { postInvoiceStatusLoading, postInvoiceSatusRes, postTicketStatusRes } = useSelector(state => state.Invoices)
  const { editDocAssignTo } = useSelector(state => state.DocumentsList)

  const [isSubmitDisable, setIsSubmitDisable] = useState(false)

  const handleCancel = () => {
    toggle()
    cancelChange()
  }

  const handleConfirm = () => {
    const data = {
        S_no,
        newValue
    }
    console.log(data)
    if (confirmAction === "invoiceStatus") {
      dispatch(postInvoicesStatus(data))
    } else if (confirmAction === "ticketStatus") {
      dispatch(postTicketStatus(data))
    } else if (confirmAction === "docAssignStatus") {
      
      const data3 ={
        userAssignTo: newValue,
        userUpdateAssign: 1,
        docId: S_no
      }
      newValue !== "" && dispatch(postDocumentAssignTo(data3))
    }
  }

useEffect(() => {
  console.log("dfdf", editDocAssignTo)
    postInvoiceSatusRes !== "" && close()
    postTicketStatusRes !== "" && close()
    editDocAssignTo !== "" && close()
}, [postInvoiceSatusRes, postTicketStatusRes, editDocAssignTo])

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={"static"}
      id="staticBackdrop"
    >
      <ModalBody>
        <Row>
          <Col lg="12" className="text-center">
            <Row>
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              />
            </Row>
            <Row>
              <h5>{`Are you sure you want to ${
                confirmAction === "invoiceStatus"
                  ? "Update Invoice Status"
                  : "Update Ticket Status"
              }?`}</h5>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleConfirm}
            disabled={isSubmitDisable}
          >
            {isSubmitDisable && (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            )}
            Yes
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmationModal
