import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import { Input } from "reactstrap"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import {  postDocumentAssignTo } from "store/add-paper/actions"
import ConfirmationModal from "pages/invoices-list/ConfirmationModal"

const UpdateAssignStatus = props => {
  const { docId, assignedTo } = props

  const [usersList, setUsersList] = useState([])
  const [confirmationModal, setConfirmationModal] = useState(false)

  const fetchUsersList  = useSelector(state => state.DocumentsList.usersList)
  const dispatch = useDispatch()

  // const userLoginId = localStorage.getItem("user_id")
  const userLoginId = 1

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Please select Status."),
    }),
    onSubmit: values => {
      //
    },
  })



  React.useEffect(() => {
    validation.setFieldValue("status", assignedTo)
  }, [assignedTo])

  useEffect(() => {
    if (fetchUsersList) {
      setUsersList(fetchUsersList)
    }
  }, [fetchUsersList])

  // useEffect(() => {
  //   const data ={
  //     userAssignTo: validation.values.status,
  //     userUpdateAssign: userLoginId,
  //     docId: docId
  //   }
  //   validation.values.status !== "" && dispatch(postDocumentAssignTo(data))
  // }, [validation.values.status])

  const handleChange = e => {
    validation.setFieldValue("status", e.target.value)
    toggle()
  }
  const toggle = () => confirmationModal ? setConfirmationModal(false) : setConfirmationModal(true)
  const cancelChange = () => {
    validation.setFieldValue("status", assignedTo)
  }


  return (
    <>
      <Input
        name="status"
        type="select"
        onChange={e => handleChange(e)}
        onBlur={validation.handleBlur}
        value={validation.values.status || ""}
        invalid={
          validation.touched.status && validation.errors.status ? true : false
        }
      >
        <option value="" disabled>
          Select Status
        </option>
        {usersList.length && usersList.map((status, index) => (
          <option key={index} value={status.id}>
            {status.name}
          </option>
        ))}
      </Input>
      <ConfirmationModal
        toggle={toggle}
        modal={confirmationModal}
        confirmAction="docAssignStatus"
        cancelChange={cancelChange}
        S_no={docId}
        newValue={validation.values.status}
        close={() => setConfirmationModal(false)}
      />
    </>
  )
}

export default UpdateAssignStatus
