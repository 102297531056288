import {
    GET_DOCUMENTS_LIST,
    GET_DOCUMENTS_LIST_SUCCESS,
    GET_DOCUMENTS_LIST_FAIL,

    GET_USERS_LIST,
    GET_USERS_LIST_SUCCESS,
    GET_USERS_LIST_FAIL,

    POST_DOCUMENT_ASSIGNTO,
    POST_DOCUMENT_ASSIGNTO_SUCCESS,
    POST_DOCUMENT_ASSIGNTO_FAIL,

    GET_DOCUMENT_ASSIGN_DETAILS,
    GET_DOCUMENT_ASSIGN_DETAILS_SUCCESS,
    GET_DOCUMENT_ASSIGN_DETAILS_FAIL,

    POST_NEW_DOCUMENT,
    POST_NEW_DOCUMENT_SUCCESS,
    POST_NEW_DOCUMENT_FAIL
} from "./actionTypes";


export const getDocumentsList = () => ({
    type: GET_DOCUMENTS_LIST,
})

export const getDocumentsListSuccess = data => ({
    type: GET_DOCUMENTS_LIST_SUCCESS,
    payload: data
})

export const getDocumentsListFail = error => ({
    type: GET_DOCUMENTS_LIST_FAIL,
    payload: error
})

// Users list
export const getUsersList = () => ({
    type: GET_USERS_LIST,
})

export const getUsersListSuccess = data => ({
    type: GET_USERS_LIST_SUCCESS,
    payload: data
})

export const getUsersListFail = error => ({
    type: GET_USERS_LIST_FAIL,
    payload: error
})

export const postDocumentAssignTo = data => ({
    type: POST_DOCUMENT_ASSIGNTO,
    payload: data
})

export const postDocumentAssignToSuccess = data => ({
    type: POST_DOCUMENT_ASSIGNTO_SUCCESS,
    payload: data
})

export const postDocumentAssignToFail = error => ({
    type: POST_DOCUMENT_ASSIGNTO_FAIL,
    payload: error
})
// get all assign history in modal open
export const getDocumentAssignDetails = params => ({
    type: GET_DOCUMENT_ASSIGN_DETAILS,
    payload: params
})

export const getDocumentAssignDetailsSuccess = data => ({
    type: GET_DOCUMENT_ASSIGN_DETAILS_SUCCESS,
    payload: data
})

export const getDocumentAssignDetailsFail = error => ({
    type: GET_DOCUMENT_ASSIGN_DETAILS_FAIL,
    payload: error
})


export const postNewDocument = data => ({
    type: POST_NEW_DOCUMENT,
    payload: data
})

export const postNewDocumentSuccess = data => ({
    type: POST_NEW_DOCUMENT_SUCCESS,
    payload: data
})      

export const postNewDocumentFail = error => ({
    type: POST_NEW_DOCUMENT_FAIL,
    payload: error
})