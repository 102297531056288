import React, { useEffect, useState } from "react"
import { useFormik } from "formik"
import { Input } from "reactstrap"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import { getTicketStatus } from "store/actions"
import ConfirmationModal from "../ConfirmationModal"

const UpdateStatusInput = props => {
  const { S_no, ticket_status } = props

  const dispatch = useDispatch()
  const [confirmationModal, setConfirmationModal] = useState(false)

  const invoicesStatus = useSelector(state => state.Invoices.invoicesStatus)
  const ticketStatus = useSelector(state => state.Invoices.ticketStatus)

  useEffect(() => {
    dispatch(dispatch(getTicketStatus()))
  }, [])
  const toggle = () =>
    confirmationModal ? setConfirmationModal(false) : setConfirmationModal(true)
  const handleChange = e => {
    validation.setFieldValue("status", e.target.value)
    toggle()
  }
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Please select Status."),
    }),
    onSubmit: values => {
      //
    },
  })
  const cancelChange = () => {
    validation.setFieldValue("status", ticket_status)
  }

  React.useEffect(() => {
    console.log(ticket_status)
    validation.setFieldValue("status", ticket_status)
  }, [ticket_status])

  return (
    <>
      <Input
        name="status"
        type="select"
        onChange={e => handleChange(e)}
        onBlur={validation.handleBlur}
        value={validation.values.status || ""}
        invalid={
          validation.touched.status && validation.errors.status ? true : false
        }
      >
        <option value="" disabled>
          Select Status
        </option>
        {ticketStatus.map((status, index) => (
          <option key={index} value={status.id}>
            {status.name}
          </option>
        ))}
      </Input>
      <ConfirmationModal
        toggle={toggle}
        modal={confirmationModal}
        confirmAction="ticketStatus"
        cancelChange={cancelChange}
        S_no={S_no}
        newValue={validation.values.status}
        close={() => setConfirmationModal(false)}
      />
    </>
  )
}

export default UpdateStatusInput
