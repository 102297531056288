import React, { useState, createContext, useEffect } from "react"

let headerData = {},
  tabelData = [],
  initialActiveRow = 1

const initialInvoiceData = {
  headerData: {},
  tabelData: [],
  memberShip: "",
}

const initialTotalData = {
  discount: 0,
  price: 0,
  lineTotal: 0,
}
const initialEmpty = "false"

export const TabelContext = createContext({
  invoiceData: initialInvoiceData,
  totalData: initialTotalData,
  activeRow: initialActiveRow,
  isEmpty: initialEmpty,
  updateHeaderDataHandler: headerData => {},
  updateTabelDataHandler: () => {},
  updateIsEmptyHandler: () => {},
  updateMembershipHandler: () => {},
})

// description
// discount

const TabelContextProvider = props => {
  const [invoiceData, setInvoiceData] = useState(initialInvoiceData)
  const [totalData, setTotalData] = useState(initialTotalData)
  const [activeRow, setActiveRow] = useState(initialActiveRow)
  const [isEmpty, setIsEmpty] = useState(initialEmpty)

  // update active row
  useEffect(() => {
    if (invoiceData.tabelData.length && invoiceData.tabelData[activeRow - 1]) {
      if (
        invoiceData.tabelData[activeRow - 1].itemCode &&
        invoiceData.tabelData[activeRow - 1].unitPrice &&
        invoiceData.tabelData[activeRow - 1].qty &&
        invoiceData.tabelData[activeRow - 1].totalPrice &&
        invoiceData.tabelData[activeRow - 1].lineTotal
      ) {
        setActiveRow(prevState => prevState + 1)
      }
    }
  }, [invoiceData])

  const updateTabelDataHandler = data => {
    const objInTabelData = tabelData.find(
      one => one.rowNumber === data.rowNumber
    )
    // add new row in datatabel
    if (!objInTabelData) {
      tabelData.push(data)
    } else {
      // edit existing row in datatabel
      tabelData[data.rowNumber - 1] = data
    }
    setInvoiceData(prevState => ({
      headerData: prevState.headerData,
      invoiceData: prevState.invoiceData,
      tabelData: tabelData,
    }))

    // Calculate total discount and price
    if (tabelData.length > 0) {
      let totalDiscount = 0,
        totalPrice = 0,
        totalLineTotal = 0
      tabelData.forEach(one => {
        totalDiscount += +one.totalPrice - +one.lineTotal
        totalPrice += +one.totalPrice
        totalLineTotal += +one.lineTotal
      })
      setTotalData({
        discount: totalDiscount,
        price: totalPrice,
        lineTotal: totalLineTotal,
      })
    }
    console.log(tabelData)
  }

  const updateHeaderDataHandler = tabelheaderData => {
    headerData = tabelheaderData
    setInvoiceData(prevState => ({
      headerData: headerData,
      tabelData: prevState.tabelData,
      invoiceData: prevState.invoiceData,
    }))
  }

  const updateMembershipHandler = value => {

    setInvoiceData(prevState => ({
      headerData: prevState.headerData,
      tabelData: prevState.tabelData,
      invoiceData: {
        ...prevState.invoiceData,
        memberShip: value,
      },
    }))
  }
  const updateIsEmptyHandler = value => {
    setIsEmpty(value)
  }

  return (
    <TabelContext.Provider
      value={{
        invoiceData,
        totalData,
        activeRow,
        updateTabelDataHandler,
        updateHeaderDataHandler,
        updateMembershipHandler,
        updateIsEmptyHandler,
        isEmpty,
      }}
    >
      {props.children}
    </TabelContext.Provider>
  )
}

export default TabelContextProvider
